<template>
  <div>
    <a-row :gutter="16">
      <!-- <a-col :span="12">
        <a-form-item label="Level">
          <a-input
            size="large"
            class="w-100"
            placeholder="VII"
            :value="data.tingkat"
            @change="(e) => handleChange(e.target.value, 'tingkat')"
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Symbol">
          <a-input
            size="large"
            class="w-100"
            placeholder="1"
            :value="data.simbol"
            @change="(e) => handleChange(e.target.value, 'simbol')"
          />
        </a-form-item>
      </a-col> -->
      <a-col :span="24">
        <a-form-item label="Basic Competence">
          <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            size="large"
            class="w-100"
            :value="data.id"
            @change="(e) => handleChange(e, 'id')"
          >
            <a-select-option
              v-for="item in kdData"
              :key="item.key"
              :value="item.id"
            >
              {{ item.no }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Knowledge">
          <a-textarea
            class="w-100"
            :value="selectedKd.nama_pengetahuan"
            disabled
            :rows="4"
          />
        </a-form-item>
        <a-form-item label="Skill">
          <a-textarea
            class="w-100"
            :value="selectedKd.nama_keterampilan"
            disabled
            :rows="4"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <div class="mt-3">
      <a-table
        :columns="selectedColumns"
        :data-source="listSelected"
        :scroll="{ y: 300 }"
        :pagination="{ pageSize: 30, hideOnSinglePage: true }"
        bordered
      >
        <div class="d-flex justify-content-end" slot="footer" slot-scope="data">
          Total Session: {{ data.length }}
        </div>
        <div
          slot="tanggal_sesi"
          slot-scope="value, record"
          class="d-flex flex-column"
        >
          <span>Week {{ record.week.week }}</span>
          <span>{{ moment(value).format("dddd, DD MMMM YYYY") }}</span>
          <p>{{ record.slot.kela.tingkat }}- {{ record.slot.kela.simbol }}</p>
          <div class="mt-2 d-flex align-items-center">
            <a-icon
              v-if="record.zoom_setting"
              type="video-camera"
              theme="twoTone"
              two-tone-color="#eb2f96"
              :style="{ fontSize: '20px' }"
              class="mr-3"
            />
            <template>
              <a-icon
                type="paper-clip"
                v-if="record.tipe_assignment == 'File Upload'"
                style="font-size: 20px; color: #1b55e3"
              />
              <a-icon
                type="align-left"
                v-if="record.tipe_assignment == 'Essay'"
                style="font-size: 20px; color: #1b55e3"
              />
              <a-icon
                type="unordered-list"
                v-if="record.tipe_assignment == 'Multiple Choice'"
                style="font-size: 20px; color: #1b55e3"
              />
            </template>
          </div>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
const selectedColumns = [
  {
    title: 'List Session Selected',
    dataIndex: 'tanggal_sesi',
    key: 'tanggal_sesi',
    scopedSlots: { customRender: 'tanggal_sesi' },
    width: 220,
  },
]
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
    listSelected: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      kdData: [],
      selectedColumns,
      moment,
      selectedKd: {
        nama_pengetahuan: null,
        nama_keterampilan: null,
      },
    }
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    fetchDataKd() {
      this.programLoadingTable = true
      this.$store.dispatch('master/FETCH_KD_MASTER', { idMapel: this.params.id_mata_pelajaran, idLevel: this.params.id_level })
        .then(res => {
          this.programLoadingTable = false
          this.kdData = res.data.map(el => {
            return {
              ...el,
              key: el.id,
            }
          })
        })
    },
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)

      if (column === 'id' && value) {
        this.selectedKd = this.kdData.find(data => data.id === value)
      } else {
        this.selectedKd = {
          nama_pengetahuan: null,
          nama_keterampilan: null,
        }
      }
    },
  },
  mounted() {
    this.fetchDataKd()
  },
}
</script>

<style>
</style>
